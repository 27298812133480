import { useSectionStyles } from "@eavfw/apps";
import {  makeid, registerQuickformView, useQuickFormDefinition } from "@eavfw/quickform-designer";

declare module "@eavfw/quickform-designer" {
    interface QuickFormDesignerState {
        activeVariable?: string;
    }
}
type QuickformVariable = {
    name: string;
    value?: string;
    order?: number;
    showInEmail?: boolean;
    showOnEndingSlide?: boolean
}
declare module "@eavfw/quickform-core" {
    interface QuickFormDefinition {
        variables: {
            [key: string]: QuickformVariable;
        }
    }
}

import { BracesVariable24Regular } from "@fluentui/react-icons";
import { FormRender, RegisterControl, RibbonBar, RibbonContextProvider, RibbonHost, StateTabProvider, useEAVApp, useModelDrivenApp } from "@eavfw/apps"


import * as React from "react";
import {
    AddSquare24Regular,
    FolderRegular,
    EditRegular,
    OpenRegular,
    Delete20Regular,
    DocumentRegular,
    DocumentPdfRegular,
    VideoRegular,
    DeleteRegular,
} from "@fluentui/react-icons";
import {
    PresenceBadgeStatus,
    Avatar,
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
    Button,
    TableColumnId,
    DataGridCellFocusMode,
    makeStyles,
    Tree,
    TreeItem,
    TreeItemLayout,
    tokens,
} from "@fluentui/react-components";







import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent
} from "@fluentui/react-components";
import { SetStateAction, useMemo } from "react";
import { capitalize } from "@eavfw/utils";
import { useQuickForm } from "@eavfw/quickform-core";



type NameCell = string;

type LastUpdatedCell = {
    label: string;
    timestamp: number;
};

type ExpressionCell = {
    value: string;
    //status: PresenceBadgeStatus;
};

type QuickFormVariable = {
    name: NameCell;
    expression: ExpressionCell;
    lastUpdated: LastUpdatedCell;
};

const items: QuickFormVariable[] = [
    {
        name: "areal",
        expression: { value: "add(mul(question('abcs'),question('abcs')),200)" },
        lastUpdated: { label: "7h ago", timestamp: 1 },
    },
    {
        name: "Thursday presentation",
        expression: { value: "add(1,2)" },
        lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    },
    {
        name: "Training recording",
        expression: { value: "add(1,2)" },
        lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    },
    {
        name: "Purchase order",
        expression: { value: "add(1,2)" },
        lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
    },
];

const columns: TableColumnDefinition<QuickFormVariable>[] = [
    createTableColumn<QuickFormVariable>({
        columnId: "variableName",
        compare: (a, b) => {
            return a.name.localeCompare(b.name);
        },
        renderHeaderCell: () => {
            return "Variable Name";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    {item.name}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<QuickFormVariable>({
        columnId: "expression",
        compare: (a, b) => {
            return a.expression.value.localeCompare(b.expression.value);
        },
        renderHeaderCell: () => {
            return "Udtryk";
        },
        renderCell: (item) => {

            const styles = useDataGridStyles();
            return (
                <TableCellLayout truncate className={styles.actionCell} content={{ className: styles.actionCell }} main={{ className: styles.mainCell }}

                >
                    <span className={styles.text}>{item.expression.value}</span>
                    <Button icon={<EditRegular />} />

                </TableCellLayout>
            );
        },
    }),
    createTableColumn<QuickFormVariable>({
        columnId: "singleAction",
        renderHeaderCell: () => {
            return "Single action";
        },
        renderCell: () => {
            return <Button icon={<OpenRegular />}>Open</Button>
        },
    }),
    createTableColumn<QuickFormVariable>({
        columnId: "actions",
        renderHeaderCell: () => {

            const [{ model }] = useEAVApp();
            const entity = model.getEntityFromKey("Expression");
            const styles = useSectionStyles();

            const localization = useMemo(() => ({
                new: capitalize(model.getLocalization("new") ?? "New"),
                delete: "Delete",
            }), []);

            const dialogStyles = useDataGridStyles();


            return (<>
                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button icon={<AddSquare24Regular />}>Add</Button>
                    </DialogTrigger>
                    <DialogSurface className={dialogStyles.dialog}>
                        <DialogBody>

                            <DialogContent>
                                <StateTabProvider >
                                    <RibbonContextProvider >



                                        <FormRender formName="Main Information"
                                            record={{}}
                                            dismissPanel={(ev) => { console.log("ProductOptionsEditor", ev); }}
                                            entityName={model.getEntityFromKey("Expression").logicalName}
                                            onChange={(data, ctx) => {
                                                console.log("ProductOptionsEditor data", data);
                                            }} />



                                    </RibbonContextProvider>
                                </StateTabProvider>


                            </DialogContent>

                        </DialogBody>
                    </DialogSurface>
                </Dialog>

            </>)
        },
        renderCell: () => {
            return (
                <>
                    <Button aria-label="Edit" icon={<EditRegular />} />
                    <Button aria-label="Delete" icon={<DeleteRegular />} />
                </>
            );
        },
    }),
];

const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case "singleAction":
            return "none";
        case "actions":
            return "group";
        default:
            return "cell";
    }
};

const useDataGridStyles = makeStyles({
    "header": {
        padding: "0.5rem 0 ",
    },
    "actionCell": { display: "flex", width: "100%", flexDirection: "row", alignItems: "center" },
    "mainCell": {
        display: "flex", width: "100%", flexDirection: "row", alignItems: "center",
        justifyContent: "space-between",
    },
    "text": {
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    "dialog": {
        minWidth: "80vw",
    }
});

export const FocusableElementsInCells = () => {

    const datagridstyles = useDataGridStyles();
    return (
        <DataGrid
            items={items}
            columns={columns}
            sortable
            selectionMode="multiselect"
            getRowId={(item: QuickFormVariable) => item.name}
            onSelectionChange={(e, data) => console.log(data)}
            style={{ minWidth: "550px" }}
        >
            <DataGridHeader >
                <DataGridRow className={datagridstyles.header}
                    selectionCell={{
                        checkboxIndicator: { "aria-label": "Select all rows" },
                    }}
                >
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<QuickFormVariable>>
                {({ item, rowId }) => (
                    <DataGridRow<QuickFormVariable>
                        key={rowId}
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select row" },
                        }}
                    >
                        {({ renderCell, columnId }) => (
                            <DataGridCell focusMode={getCellFocusMode(columnId)}>
                                {renderCell(item)}
                            </DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    );
};
const useQuickFormVariables = () => {

    const { updateQuickFormPayload, quickformpayload, setView } = useQuickFormDefinition();


    const setActiveVariable = (variable?: string) => updateQuickFormPayload(old => { old.__designer = { ...old.__designer ?? {} }; old.__designer.activeVariable = variable; return { ...old }; });
    const activeVariable = quickformpayload.__designer?.activeVariable;

    const handleAddVariable = () => {
        const id = makeid(16);
        updateQuickFormPayload((old) => {

            old.variables = {
                ...old.variables ?? {}
            };

            old.variables[id] = { name: "New Variable" };

            return { ...old };
        });
        setActiveVariable(id);
    };

    const handleVariableClick = (key: string) => {
        setActiveVariable(key);
        setView("variables");
    };

    const handleRemoveVariable = (key: string) => {
        updateQuickFormPayload((old) => {
            if (old.variables) {
                delete old.variables[key];
                setView("variables");
            }
            return { ...old };
        });
    };

    const sortedVariables = useMemo(() => {

        return Object.entries(quickformpayload.variables ?? {}).map(([key, question], index) => [key, question, index] as [string, typeof question, number])
            .sort(([_, qa, ai], [__, qb, bi]) => (qa.order ?? ai) - (qb.order ?? bi));

    }, [quickformpayload]);

    const variable = activeVariable && quickformpayload.variables[activeVariable];

    const updateVariable = (data: QuickformVariable) => updateQuickFormPayload((old) => {

        old.variables = {
            ...old.variables ?? {},
            [quickformpayload.__designer!.activeVariable!]: data
        };

        return { ...old };
    });

    return { handleRemoveVariable, handleVariableClick, handleAddVariable, activeVariable, setActiveVariable, sortedVariables, variable, updateVariable }

}
export const VariablesNav = () => {

   

    const { sortedVariables, activeVariable, handleVariableClick, handleRemoveVariable, handleAddVariable } = useQuickFormVariables();
 

  

  


   

  

    return (
        <Tree>
            {sortedVariables.map(([key, question], index) => (
                <TreeItem key={key} itemType="leaf" onClick={() => handleVariableClick(key)}>
                    <TreeItemLayout style={activeVariable === key ? { background: tokens.colorNeutralBackground1Hover } : {}} iconBefore={<BracesVariable24Regular />}
                        actions={<Button
                            aria-label="Remove item"
                            appearance="subtle"
                            onClick={() => handleRemoveVariable(key)}
                            icon={<Delete20Regular />}
                        />}>
                        {question.name}
                    </TreeItemLayout>
                </TreeItem>
            ))}
            <TreeItem itemType="leaf">
                <TreeItemLayout iconBefore={<AddSquare24Regular />}  >
                    <Button onClick={handleAddVariable} appearance="transparent"  >
                        Add Variable
                    </Button>
                </TreeItemLayout>
            </TreeItem>
        </Tree >
    )
}

export const VariablesView = () => {

    const sectionstyles = useSectionStyles();
  //  const { updateQuickFormPayload, quickformpayload, setView } = useQuickFormDefinition();
    const [{ model }] = useEAVApp();

    const { handleAddVariable, activeVariable, variable, updateVariable } = useQuickFormVariables();




    if (!activeVariable) {

        return (<div className={sectionstyles.section}>
            <Button onClick={handleAddVariable} appearance="transparent"  >
                Add Variable
            </Button>
        </div>);
    }


    return (<StateTabProvider >
        <RibbonContextProvider >



            <FormRender key={activeVariable} formName="Main Information"
                record={variable}
                dismissPanel={(ev) => { console.log("ProductOptionsEditor", ev); }}
                entityName={model.getEntityFromKey("Expression").logicalName}
                onChange={(data, ctx) => {
                    console.log("Expression data", data);
                    updateVariable(data);
                }} />



        </RibbonContextProvider>
    </StateTabProvider>
)
}

registerQuickformView("variables", { icon: <BracesVariable24Regular />, navKey: "Variable", title: "Variables", view: VariablesView, nav: VariablesNav });