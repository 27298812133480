import { useSWRFetch } from '@eavfw/manifest';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { EmbedProps } from 'powerbi-client-react';
import { makeStyles } from '@fluentui/react';
import { useSectionStyles } from '@eavfw/apps';
import { mergeClasses } from '@fluentui/react-components';
const PowerBIEmbed = dynamic<EmbedProps>(() => import('powerbi-client-react').then(m => m.PowerBIEmbed), { ssr: false });
export type FormsConfig = {

    /**
     * The entity viewer will only show the FormPicker when there are more than one form. This can be overridden here
     */
    alwaysShowFormSelector?: boolean;
}

const defaultConfig: FormsConfig = { // Omit<ModelDrivenEntityViewerProps, keyof (WithRouterProps & WithAppProps)> = {
    alwaysShowFormSelector: false
}

const usePowerBiDashboardStyles = makeStyles({
    root: {
        height: '100%',
    }
});
export const PowerBiDashboard: React.FC = (props) => {

    const router = useRouter();
    const { data: embedParams, isLoading, error } = useSWRFetch(`/providers/microsoft.powerbi/reports/sales-dashboard`);
    const styles = usePowerBiDashboardStyles();
    const section = useSectionStyles();
    const selectedView = router.query.dashboard as string;;
    const [embedconfig, setocnfig] = useState<any>();
    
    let dynamicallyImportPackage = async () => {
        const models = await import('powerbi-client').then(k => k.models);

        setocnfig({
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            // id: '<id>',
            embedUrl: embedParams.EmbedReport[0].EmbedUrl,
            accessToken: embedParams.EmbedToken.token,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    }
                },
                background: models.BackgroundType.Transparent,
            }
        });
        // you can now use the package in here
    }

    useEffect(() => {
        if (!isLoading) {
            dynamicallyImportPackage()
        }
        if (error) {
            throw new Error(error);
        }
    }, [isLoading, error])

    if (!embedconfig)
        return <div>loading</div>;

    //Question : Should the home items retrival be in the page to make model driven grid viewer simpler?
    //Answer: Currently the abstraction of pages/items seems simpler to be tighly coubled with the gridviewer.

    return (
        <PowerBIEmbed
            embedConfig={embedconfig}

            eventHandlers={
                new Map([
                    ['loaded', function () { console.log('Report loaded'); }],
                    ['rendered', function () { console.log('Report rendered'); }],
                    ['error', function (event: any) { console.log(event.detail); }]
                ])
            }

            cssClassName={mergeClasses(section.section, styles.root)}

            getEmbeddedComponent={(embeddedReport) => {
                //  this.report = embeddedReport as Report;
            }}
        />

    );
}