"use client";
import React from "react";
import { textInputSchema } from "./TextInputSchema";
import { BaseInputComponent } from "../baseinput/BaseInputComponent";
import { InputComponentType, registerInputComponent, registerInputTypeValidator, ValidationResult } from "@eavfw/quickform-core";
import { IconType } from "../icons/IconResolver";
 
export type TextProperties = {
    inputType: "text";
    defaultValue?: string;
    unit?: string;
    beforeIcon?: IconType;
    afterIcon?: IconType
}

export const TextInput: InputComponentType<TextProperties> = (props) => {
    return <BaseInputComponent type="text" {...props} />
}

/* This property assignment grants QuickformDesigner metadata information about which properties the inputcomponent needs */
TextInput.inputSchema = textInputSchema;
registerInputComponent("text", TextInput);

const validateRequired = (output: any, properties: TextProperties): Promise<ValidationResult> => {
    return new Promise((resolve) => {
        const valid = typeof output === "string" && output.length > 0;
        resolve({
            isValid: valid,
            message: valid ? "" : `feltet skal udfyldes.`,
            validatedOutput: output,
        });
    });
};
registerInputTypeValidator("text", validateRequired);