import { RegisterControl } from "@eavfw/apps";
import { useQuickFormDefinition } from "@eavfw/quickform-designer";
import { Button, Field, Textarea, makeStyles } from "@fluentui/react-components";
import { Field as RJSFField, getUiOptions } from "@rjsf/utils";
import { RefObject, useRef } from "react";

const useExpressionEditorFieldStyes = makeStyles({
    field: {
        display: "flex",
        flexDirection: "row",
        gap: "1rem"
    },
    textAreaContainer: {
        flexGrow:1
    },
    textArea: {
        width: "100%"
    },
    helper: {
        
        display: "flex",
        flexDirection: "column",
    }
});

import {
    Menu,
    MenuItem,
    MenuItemProps,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    Tab,
    TabList,
    mergeClasses,
    tokens,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from "@fluentui/react-components";


/**
 * A menu item for an overflow menu that only displays when the tab is not visible
 */
const OverflowMenuItem = (props: OverflowMenuItemProps) => {
    const { tab, onClick } = props;
    const isVisible = useIsOverflowItemVisible(tab.id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem key={tab.id} icon={ tab.icon}  onClick={onClick}>
            <div>{tab.name}</div>
        </MenuItem>
    );
};  


const useOverflowMenuStyles = makeStyles({
    menu: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    menuButton: {
        alignSelf: "center",
    },
});

type ExampleTab = {
    id: string;
    name: string;
    logicalName: string;
    icon: React.ReactElement;
    expression: string;
};
type OverflowMenuItemProps = {
    tab: ExampleTab;
    // eslint-disable-next-line @nx/workspace-consistent-callback-type
    onClick: MenuItemProps["onClick"];
};

import {
    BookQuestionMarkRegular,
    BracesVariableRegular,
    MoreHorizontalFilled,
    MoreHorizontalRegular,
    bundleIcon
} from "@fluentui/react-icons";

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

type OverflowMenuProps = {
    // eslint-disable-next-line @nx/workspace-consistent-callback-type
    onTabSelect?: (tabId: string) => void;
    tabs: ExampleTab[]
};

/**
 * A menu for selecting tabs that have overflowed and are not visible.
 */
const OverflowMenu = (props: OverflowMenuProps) => {
    const { onTabSelect, tabs } = props;
    const { ref, isOverflowing, overflowCount } =
        useOverflowMenu<HTMLButtonElement>();

    const styles = useOverflowMenuStyles();

    const onItemClick = (tabId: string) => {
        onTabSelect?.(tabId);
    };

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu hasIcons>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    appearance="transparent"
                    className={styles.menuButton}
                    ref={ref}
                    icon={<MoreHorizontal />}
                    aria-label={`${overflowCount} more tabs`}
                    role="tab"
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList className={styles.menu}>
                    {tabs.map((tab) => (
                        <OverflowMenuItem
                            key={tab.id}
                            tab={tab}
                            onClick={() => onItemClick(tab.id)}
                        />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};


const useExampleStyles = makeStyles({
    example: {
       // backgroundColor: tokens.colorNeutralBackground2,
        overflow: "hidden",
        padding: "5px",
        zIndex: 0, //stop the browser resize handle from piercing the overflow menu
    },
    horizontal: {
        height: "fit-content",
        minWidth: "150px",
        resize: "horizontal",
        width: "600px",
    },
    vertical: {
        height: "250px",
        minHeight: "100px",
        resize: "vertical",
        width: "fit-content",
        display: "flex",
        alignContent: "stretch",
        alignItems: "stretch",
        justifyContent: "stretch",
        justifyItems: "stretch",
    },
});

const VerticalExample = ({ textAreaRef, onChange }: { textAreaRef: RefObject<HTMLTextAreaElement>, onChange: (a: string) => void }) => {
    const styles = useExampleStyles();

   // const [selectedTabId, setSelectedTabId] = useState<string>("today");
    const { quickformpayload } = useQuickFormDefinition();

    

    const tabs = Object.entries(quickformpayload.questions).map(([qkey, q]) => (
        { expression: `question('${q.logicalName}')`, id: qkey, name: q.displayName ?? q.text, logicalName: q.logicalName, icon: <BookQuestionMarkRegular /> } as ExampleTab
    )).concat(
        Object.entries(quickformpayload.variables).filter(([vkey, v]) => vkey !== quickformpayload.__designer?.activeVariable).map(([qkey, q]) => (
            { expression:`variable('${q.name}')`, id: qkey, name: q.name, logicalName: q.name, icon: <BracesVariableRegular /> } as ExampleTab
        ))
    );

    const onTabSelect = (tabId: string) => {
      //  setSelectedTabId(tabId);
        const tab = tabs.find(x => x.id === tabId);
        
        if (textAreaRef.current && tab) {
            const textArea = textAreaRef.current;
            const start = textArea.selectionStart;
            const end = textArea.selectionEnd;
            const text = textArea.value;
            const before = text.substring(0, start);
            const after = text.substring(end, text.length);
            textArea.value = before + tab.expression + after;
           
            setTimeout(() => { onChange(textArea.value); },0);

            textArea.selectionStart = textArea.selectionEnd = start + tab.expression.length;
            textArea.focus();
        }

    };
    

    return (
        <div className={mergeClasses(styles.example, styles.vertical)}>
            <Overflow minimumVisible={2} overflowAxis="vertical">
                <TabList
                    vertical
                   
                    onTabSelect={(_, d) => onTabSelect(d.value as string)}
                >
                    {tabs.map((tab) => {
                        return (
                            <OverflowItem
                                key={tab.id}
                                id={tab.id}
                                
                            >
                                <Tab value={tab.id} icon={ tab.icon}>
                                    {tab.name}
                                </Tab>
                            </OverflowItem>
                        );
                    })}
                    <OverflowMenu onTabSelect={onTabSelect} tabs={ tabs} />
                </TabList>
            </Overflow>
        </div>
    );
};


export const ExpressionEditorField: RJSFField = ({ onChange, formData, title, required, schema, uiSchema, registry: { globalUiOptions, ...registry} }, ... props) => {

    
    console.log("ExpressionEditorField", [schema, props, formData])
    const { quickformpayload } = useQuickFormDefinition();
    const uiOptions = getUiOptions(uiSchema, globalUiOptions);
    const styles = useExpressionEditorFieldStyes();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
 
    return (
        <Field label={schema.title} required={required}>
            <div className={styles.field}>
                <div className={styles.textAreaContainer}>
                    <Textarea className={styles.textArea} rows={10} resize="vertical" ref={textAreaRef} defaultValue={formData ?? ''} value={formData??''} onChange={(e, x) => onChange(x.value)} />
                </div>
                <div className={styles.helper}>
                    <VerticalExample textAreaRef={textAreaRef} onChange={onChange} />
                   
                </div>
            </div>
        </Field>
    );
};

RegisterControl("ExpressionEditorField", ExpressionEditorField);