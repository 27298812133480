import { useSectionStyles } from "@eavfw/apps";
import { CustomerPriceEditor } from "./ProductPriceEditor";


export const CustomerPriceDashboard = () => {
    const styles = useSectionStyles();
    return (
        <CustomerPriceEditor />
    )
}

export default CustomerPriceDashboard;