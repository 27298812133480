import { InputComponentMetadata } from "@eavfw/quickform-core";
import { JSONSchema7 } from "json-schema";
import { ProductOptionInputProps } from "./ProductOptionInput";
export const ProductOptionInputSchema: InputComponentMetadata<ProductOptionInputProps> = {
    label: "Product Option",
    field: {
        type: "select",
        // typeProvider: (a) => a.maxItems === 1 ? "select" : "multiselect",
        listValuesProvider: (a) => Object.entries(a.options ?? {}).map(([okey, o]) => (typeof (o) === "string" ?
            {
                label: o,
                name: okey
            } : { label: o.label, name: okey }))
    },
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        // Notice here we change the ui component from the generic quickform to our customer specific component -> ../Designer/ButtonsOptionsField
        //productQuestion: {
        //    "ui:field": "ProductLookupField",
        //    "ui:supportProjectReference": true
        //},
        products: {
            "ui:field": "ProductLookupField",
            "ui:multiselect": true
        },
        productOption: {
            "ui:field": "ProductOptionLookupField",
            "ui:productQuestion": "productQuestion"
        },
        options: {
            "ui:widget": "hidden"
        }
    },
    schema: {
        type: "object",

        properties: {
            text: {
                title: "Text",
                type: "string"
            },
            //productQuestion: {
            //    title: "Products",
            //    type: "string",
            //},
            products: {
                title: "Products",
                type: "array",
                items: {
                    type: "string"
                }
            },     
            useDropdown: {
                type: "boolean",
            },
            options: {
                type: "object",
            }
        },         
        "dependencies": {
            "productQuestion": {
                "properties": {
                    productOption: {
                        title: "Option",
                        type: "string",
                    },
                },
                "required": [
                    "productOptions"
                ]
            },
            "products": {
                "properties": {
                    productOption: {
                        title: "Option",
                        type: "string",
                    },
                },
                "required": [
                    "productOptions"
                ]
            }
        }
    } as JSONSchema7
}