import { InputComponentMetadata} from "@eavfw/quickform-core";
import { NumberProperties } from "./NumberInput";

export const numberInputSchema: InputComponentMetadata<NumberProperties> = {
    label: "Number",
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        product: {
            "ui:field": "ProductLookupField",
            "ui:supportProjectReference": true
        },
        unit: {
            "ui:field": "UnitLookupField",
        },
    },
    schema: {
        type: "object",

        properties: {
            product: {
                title: "Product",
                type: "string",
            },
            text: {
                title: "Text",
                type: "string"
            },
            placeholder: {
                title: "Placeholder",
                type: "string"
            },
            paragraph: {
                title: "Paragraph",
                type: "string"
            },
            min: {
                title: "Minimum Value",
                type: "number"
            },
            max: {
                title: "Maximum Value",
                type: "number"
            },
            defaultValue: {
                title: "Default",
                type: "number"
            },
            unit: {
                title: "Unit",
                type: "string"
            },
            helperText: {
                title: "Helper text",
                description: "This text will be shown next to the input field",
                type: "string"
            }
        }
    }, field: {
        type: "text",
    }
}