import { useModelDrivenApp, useSectionStyles } from "@eavfw/apps";








import {
    Body1,
    Caption1,
    makeStyles,
    mergeClasses,
    tokens,
    Title1,
    Subtitle2,
    CarouselSlider,
    Tooltip,
    Button,
} from "@fluentui/react-components";
import {
    Carousel,
    CarouselAnnouncerFunction,
    CarouselCard,
    CarouselNav,
    CarouselNavButton,
    CarouselNavContainer,
    CarouselViewport,
} from "@fluentui/react-components";
import * as React from "react";
import { QuotationEmbedScriptComponent } from "../QuotationEmbedScriptComponent";
import { queryEntitySWR } from "@eavfw/manifest";

const useClasses = makeStyles({
    form: {
        overflow: "auto",
    },
    viewport: {
        height: "auto",
    },
    carouselContainer: {
        height: "auto",
    },
    carousel: {
        height: "100%",
    },
    flex: {
        display: "flex",
        flexDirection: "column",
    },
    card: {
        width: "95%",
    },
    slider: {
        gap: "10px",
        height: "100%",
    },
    wireframe: {
        backgroundColor: tokens.colorNeutralBackground3,
        border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,

        display: "flex",
        flexDirection: "column",
        gap: "12px",
        placeContent: "center",

        padding: "40px",
        height: "100%",

        position: "relative",
    },
    wireframeEven: {
        backgroundColor: tokens.colorBrandBackground2,
        border: `${tokens.strokeWidthThin} solid ${tokens.colorBrandStroke1}`,
    },
    wireframeInfo: {
        position: "absolute",
        right: "12px",
        top: "12px",

        backgroundColor: tokens.colorPaletteRedBackground2,
        border: `${tokens.strokeWidthThin} dotted ${tokens.colorPaletteRedBorder2}`,

        fontSize: tokens.fontSizeBase200,
        padding: "4px 8px",
        display: "flex",
        gap: "4px",
    },
    wireframeSmall: {
        minWidth: "100px",
        padding: "40px 20px",
    },
    wireframeMedium: {
        minWidth: "200px",
        padding: "40px 20px",
    },
    wireframeLarge: {
        minWidth: "650px",
    },
    desktop: {
        width: "100%",
        margin: "0 auto",
    },
    tablet: {
        width: "700px",
        margin: "0 auto",
    },
    mobile: {
        width: "400px",
        margin:"0 auto",
    }
});
import { ViewDesktopMobileRegular, TabletRegular, DesktopRegular } from "@fluentui/react-icons";
import { useState } from "react";

const WireframeContent: React.FC<{
    appearance: "odd" | "even";
    children: React.ReactNode;
    size?: "small" | "medium" | "large";
}> = (props) => {
    const classes = useClasses();
    const [view,setView] = useState<"desktop"|"mobile"|"tablet">("desktop");
    return (
        <div
            className={mergeClasses(
                classes.wireframe,
                props.appearance === "even" && classes.wireframeEven,
                props.size === "small" && classes.wireframeSmall,
                props.size === "medium" && classes.wireframeMedium,
                props.size === "large" && classes.wireframeLarge
            )}
        >
            <div className={classes.wireframeInfo}>

                <Tooltip content="Medium with calendar icon only" relationship="label">
                    <Button icon={<ViewDesktopMobileRegular />} onClick={() => setView("mobile")} />
                </Tooltip>
                <Tooltip content="Medium with calendar icon only" relationship="label">
                    <Button icon={<TabletRegular />} onClick={() => setView("tablet")} />
                </Tooltip>
                <Tooltip content="Medium with calendar icon only" relationship="label">
                    <Button icon={<DesktopRegular />} onClick={() => setView("desktop")} />
                </Tooltip>

            </div>
            <div className={classes[view]}>
                {props.children}
            </div>
        </div>
    );
};

const getAnnouncement: CarouselAnnouncerFunction = (
    index: number,
    totalSlides: number,
    slideGroupList: number[][]
) => {
    return `Carousel slide ${index + 1} of ${totalSlides}`;
};

export const Responsive = () => {
    const classes = useClasses();
    const app = useModelDrivenApp();
    const { data: forms } = queryEntitySWR(app.getEntityFromKey("Quotation Form"));


    return (
        <Carousel className={mergeClasses(classes.carousel, classes.flex)} draggable announcement={getAnnouncement} >
            <CarouselViewport className={classes.viewport}>
                <CarouselSlider className={mergeClasses(classes.slider)}>
                    {forms?.items.map((form, index) => (
                        <CarouselCard autoSize key={form.id} aria-label={`${index} of ${forms?.items.length??0}`} className={classes.card}>
                            <WireframeContent appearance="odd" size="large"> 
                                <QuotationEmbedScriptComponent className={classes.form} formid={form.id} showEmbed={false} showLabel={false} />
                            </WireframeContent>
                        </CarouselCard>
                    ))}
                     
                </CarouselSlider>
            </CarouselViewport>

            <CarouselNavContainer
                layout="inline"
                next={{ "aria-label": "go to next" }}
                prev={{ "aria-label": "go to prev" }}
            >
                <CarouselNav>
                    {(index) => (
                        <CarouselNavButton aria-label={`Carousel Nav Button ${index}`} />
                    )}
                </CarouselNav>
            </CarouselNavContainer>
        </Carousel>
    );
};





export const CustomerQuotationFormsView = () => {
    const styles = useSectionStyles();
    const styles2 = useClasses();
    return (
        <div className={mergeClasses(styles.section, styles2.carouselContainer)}>

            <Responsive />
        </div>
    )
}

export default CustomerQuotationFormsView;