import { FormRender, RegisterControl, RibbonBar, RibbonContextProvider, RibbonHost, StateTabProvider, useEAVApp, useModelDrivenApp, useSectionStyles } from "@eavfw/apps"
import { useEAVForm } from "@eavfw/forms";
import { IRecord, PolyLookupType, TypeFormDefinition, deleteRecordSWR, queryEntitySWR } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { capitalize } from "@eavfw/utils";
import { ICommandBarItemProps } from "@fluentui/react";
import { FlatTreeItemProps, HeadlessFlatTreeItemProps, Input, InputOnChangeData, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TreeSelectionValue, mergeClasses, useHeadlessFlatTree_unstable } from "@fluentui/react-components";


import {
    FlatTree,
    FlatTreeItem,
    TreeItemLayout,
    TreeItemValue,
    Spinner,
    makeStyles,
    TreeItemOpenChangeData,
    TreeItemOpenChangeEvent,
} from "@fluentui/react-components";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useStyles = makeStyles({
    screenReadersOnly: {
        position: "absolute",
        width: "1px",
        height: "1px",
        margin: "-1",
        overflow: "hidden",
        clip: "rect(0,0,0,0)",
        whiteSpace: "nowrap",
    },
});


type ProductPriceRange = {
    $type: string,
    id: string,
    name: string,
    unitprice: number;
    rangeprice: number;
}
type ProductPriceRangeProductOptionValueReference = {
    $type: string,
    id: string,
    productpricerange: ProductPriceRange,
}

type ProductOptionValue = {
    $type: string,
    id: string,
    name: string,
    priceamount: number;
    taxable: boolean;
    productpricerangeproductoptionvaluereferences: Array<ProductPriceRangeProductOptionValueReference>
}
type ProductOption = {
    $type: string,
    id: string,
    name: string,
    productoptionvalues: ProductOptionValue[]
}
type ProductOptionTemplateProductReference = {
    $type: string,
    id: string,
    productoptionid: string,
    productoption: ProductOption,
    productid: string,
}
type ProductOptionCustomerProductReference = {
    $type: string,
    id: string,
    productoptionid: string,
    productoption: ProductOption,
    productid: string,
}
type Unit = {
    value: string;
}
type ProductPriceRangeCustomerProductReference = {
    $type: string,
    id: string,
    productpricerange: ProductPriceRange,
}
type CustomerProduct = {
    $type: string,
    id: string,
    customerid: string;
    name: string;
    priceamount: number;
    sku: string;
    taxable: boolean;
    unit: Unit,
    productpricerangecustomerproductreferences: Array<ProductPriceRangeCustomerProductReference>
}

type RowItem = {
    taxable?: boolean;
    price?: number;
    sku?: string;
    unit?: string;
    productpricerange?: ProductPriceRange[]
}

type SubtreeProps = {
    recordId: string;
    // treeItemProps: FlatTreeItemProps;
    value: TreeItemValue;
    label: string;
    onSelect?: any;
    entityKey: string;
    onDataLoading?(): void;
    onDataLoaded?(): void;
    parentProperty: string;
    size: number;
    level: number;
    item: RowItem
    mutate: () => void;
};


const CurrencyCell = ({ value, unit, entityCollectionSchemaName, recordId, field = "priceamount", mutate }: { mutate: () => void; value: number | undefined, unit: string | undefined, entityCollectionSchemaName: string, recordId: string, field?: string }) => {

    const [isEdit, setIsEdit] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const onChange = async (newValue:string) => {
       
        if (parseFloat(newValue) !== value) {
            setIsSaving(true);
            let rsp = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${entityCollectionSchemaName}/records/${recordId}`, {
                method: recordId ? "PATCH" : "POST",
                body: JSON.stringify({
                    [field]: parseFloat(newValue)
                }),
                credentials: "include"
            });

           

            if (rsp.ok) {
                mutate();
                setIsSaving(false);
            } else {
                alert("Der skete en fejl, reload");
            }
        }

      
        setIsEdit(false);
    };
    


    return (<TableCell onClick={() => setIsEdit(o => !o)}>

        {isEdit && <Input autoFocus type="number" defaultValue={value?.toString()} onBlur={(e) => { onChange(e.target.value); }} />}
        {!isEdit && typeof value === "number" && <>{new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(value)}{unit && `/${unit?.toLowerCase()}`}</>}
    </TableCell>
    )
}





const ProductOptionNode: React.FC<SubtreeProps> = ({
    entityKey,
    parentProperty,
    //  treeItemProps,
    onDataLoaded,
    onDataLoading,
    value, recordId,
    label,
    onSelect,
    size,
    level, item
}) => {
    const [open, setOpen] = useState(false);

    const [{ model }] = useEAVApp();
    // useQuery here is just a helper to simulate async data fetching
    // you can use any other async data fetching library like react-query, swr, etc.
    const { data, isLoading, mutate } = queryEntitySWR<ProductOptionValue>(model.getEntityFromKey("Product Option Value"),
        new ODataBuilder<ProductOptionValue>()
            .select("name", "id", "priceamount", "taxable")
            .expandCollection("productpricerangeproductoptionvaluereferences", p => p.expand("productpricerange"))
            .filter(`productoptionid eq ${recordId}`).build(), open)

    // we need to focus the first item when the subtree is opened
    const firstItemRef = useRef<HTMLDivElement>(null);

    const handleOpenChange = useCallback(
        (e: TreeItemOpenChangeEvent, data: TreeItemOpenChangeData) => {
            setOpen(data.open);
        },
        [setOpen]
    );


    useEffect(() => {
        if (open && data && !isLoading) {
            onDataLoaded?.();
            firstItemRef.current?.focus();
        }
    }, [open, isLoading, onDataLoaded]);

    return (
        <>
            <TableRow>
                <TableCell>
                    <FlatTreeItem
                        value={value}
                        aria-level={level}
                        aria-setsize={size}
                        aria-posinset={1}
                        itemType={isLoading || data?.items.length > 0 ? "branch" : "leaf"}
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                        <TreeItemLayout
                            expandIcon={open && isLoading ? <Spinner size="tiny" /> : undefined}
                        >
                            {label?.toString()}
                        </TreeItemLayout>
                    </FlatTreeItem>

                </TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>    
            </TableRow>
            {open &&
                data?.items?.map((optionvalue, index) => (
                    <>
                        <TableRow>
                            <TableCell>
                                <FlatTreeItem
                                    key={optionvalue.id}
                                    ref={index === 0 ? firstItemRef : null}
                                    parentValue={value}
                                    value={`${optionvalue.$type}:${optionvalue.id}`}
                                    aria-level={level + 1}
                                    aria-setsize={data.items.length}
                                    aria-posinset={index + 1}
                                    itemType={optionvalue.productpricerangeproductoptionvaluereferences.length > 0 ? "branch" : "leaf"} onClick={e => onSelect(optionvalue)}
                                >
                                    <TreeItemLayout>{optionvalue.name}</TreeItemLayout>
                                </FlatTreeItem>
                            </TableCell>

                            <CurrencyCell mutate={mutate} entityCollectionSchemaName="ProductOptionValues" recordId={optionvalue.id} value={optionvalue.priceamount} unit={item.unit} />
                            <TableCell>{optionvalue.taxable === true ? "Ja" : "Nej"}</TableCell>    
                        </TableRow>
                        {optionvalue.productpricerangeproductoptionvaluereferences && optionvalue.productpricerangeproductoptionvaluereferences.map((value, index) => (
                            <TableRow>
                                <TableCell>
                                    <FlatTreeItem
                                        key={value.id}

                                        parentValue={`${optionvalue.$type}:${optionvalue.id}`}
                                        value={`${value.$type}:${value.id}`}
                                        aria-level={level + 2}
                                        aria-setsize={optionvalue.productpricerangeproductoptionvaluereferences.length}
                                        aria-posinset={index + 1}
                                        itemType="leaf" onClick={e => onSelect(value)}
                                    >
                                        <TreeItemLayout>{value.productpricerange.name}</TreeItemLayout>
                                    </FlatTreeItem>
                                </TableCell>
                                

                                <CurrencyCell mutate={mutate} entityCollectionSchemaName="ProductPriceRanges" field={typeof value.productpricerange.unitprice === "number" ? "unitprice":"rangeprice"} recordId={value.productpricerange.id} value={value.productpricerange.unitprice ?? value.productpricerange.rangeprice} unit={item.unit} />
                                <TableCell>N/A</TableCell>    
                            </TableRow>
                        ))}
                    </>
                ))}
        </>
    );
};




const CustomerProductNode: React.FC<SubtreeProps> = ({
    entityKey,
    parentProperty,
    //  treeItemProps,
    onDataLoaded,
    onDataLoading,
    value, recordId,
    label,
    onSelect,
    item,
    mutate
}) => {
    const [open, setOpen] = useState(false);

    const [{ model }] = useEAVApp();
    // useQuery here is just a helper to simulate async data fetching
    // you can use any other async data fetching library like react-query, swr, etc.
    const { data, isLoading, mutate:optionMutate } = queryEntitySWR<ProductOptionCustomerProductReference>(model.getEntityFromKey("Product Option Customer Product Reference"),
        new ODataBuilder<ProductOptionCustomerProductReference>()
            .select("id", "productoptionid")
            .expand("productoption", o => o
                .select("name", "id")
                .expandCollection("productoptionvalues", v => v))
                
            
            .filter(`customerproductid eq ${recordId}`).build(), open)

    // we need to focus the first item when the subtree is opened
    const firstItemRef = useRef<HTMLDivElement>(null);

    const handleOpenChange = useCallback(
        (e: TreeItemOpenChangeEvent, data: TreeItemOpenChangeData) => {
            setOpen(data.open);
        },
        [setOpen]
    );


    useEffect(() => {
        if (open && data && !isLoading) {
            onDataLoaded?.();
            firstItemRef.current?.focus();
        }
    }, [open, isLoading, onDataLoaded]);

    return (
        <>
            <TableRow>
                <TableCell>


                    <FlatTreeItem
                        value={value}
                        aria-level={1}
                        aria-setsize={3}
                        aria-posinset={1}
                        itemType={isLoading || data?.items.length > 0 ? "branch" : "leaf"}
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                        <TreeItemLayout
                            expandIcon={open && isLoading ? <Spinner size="tiny" /> : undefined}
                        >



                            {label?.toString()}

                        </TreeItemLayout>
                    </FlatTreeItem>
                </TableCell>
                <CurrencyCell mutate={mutate} entityCollectionSchemaName="CustomerProducts" recordId={recordId} value={item.price} unit={item.unit} />
               
                <TableCell>{item.taxable === true ? "Ja":"Nej"}</TableCell>                
            </TableRow>
            {open && item.productpricerange && item.productpricerange.map((range, index) => (
                <TableRow>
                    <TableCell>
                        <FlatTreeItem
                            key={range.id}

                            parentValue={value}
                            value={`${range.$type}:${range.id}`}
                            aria-level={2}
                            aria-setsize={item.productpricerange!.length}
                            aria-posinset={index + 1}
                            itemType="leaf" onClick={e => onSelect(range)}
                        >
                            <TreeItemLayout>{range.name}</TreeItemLayout>
                        </FlatTreeItem>
                    </TableCell>


                    <CurrencyCell mutate={mutate} entityCollectionSchemaName="ProductPriceRanges" field={typeof range.unitprice === "number" ? "unitprice" : "rangeprice"} recordId={range.id} value={range.unitprice ?? range.rangeprice} unit={item.unit} />
                    <TableCell>N/A</TableCell>
                </TableRow>
            ))}
            {open &&
                data?.items?.map((option, index) => (

                    <ProductOptionNode mutate={optionMutate} item={{ unit: item.unit }} level={2} size={data?.items.length} value={`${option.$type}:${option.id}`} label={option.productoption.name} entityKey="Product Option Value" recordId={option.productoptionid} parentProperty="productoptionid" />

                ))}
        </>
    );
};



const useStackStyles = makeStyles({
    tree: {
        minWidth: "200px",
        flexGrow: 1
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
        '&.horizontal': {
            flexDirection: 'row',
        },
        '&.vertical': {
            flexDirection: 'column',
        },
        '&.fill': {
            width: '100%',
        },
        '&.grow': {
            flexGrow: '1',
        }
    },

})

export default function ProductOptionsEditor() {

    const [checkedItems, setCheckedItems] = useState<Array<[TreeItemValue, TreeSelectionValue]>>([]);

    const [{ formValues }, { onChange }] = useEAVForm(c => ({ formValues: c.formValues }));
    const [{ model }] = useEAVApp();
    //  const entity = model.getEntityFromKey("Cu");
    //  const optionValueEntity = model.getEntityFromKey("Product Option Value");

    const router = useRouter();

    //const localization = useMemo(() => ({
    //    new: capitalize(model.getLocalization("new") ?? "New"),
    //    delete: "Delete",
    //}), []);

    // const [newRecord, setNewRecord] = useState<{ ref: ProductOptionTemplateProductReference, item: Partial<ProductOption> }>();
    // const [record, setRecord] = useState<{ ref: ProductOptionTemplateProductReference, item: ProductOptionValue }>();





    const { data, isLoading, mutate } = queryEntitySWR<CustomerProduct>(model.getEntityFromKey("Customer Product"),
        new ODataBuilder<CustomerProduct>()
            .select("id", "name", "priceamount", "sku", "taxable")
            .expand("unit", u => u)
            .expandCollection("productpricerangecustomerproductreferences", r => r.select("id", "productpricerange").expand("productpricerange"))
            .filter(`customerid eq ${formValues.id}`)
            .build());




    console.log("ProductOptionsEditor", [checkedItems, formValues, data]);
    const styles = useSectionStyles();
    const stackStyles = useStackStyles();
    return (
        <div className={mergeClasses(stackStyles.stack, styles.fullWidth)}>
            <StateTabProvider >

                <div className={mergeClasses(stackStyles.stack, 'horizontal', 'grow')}>

                    <FlatTree checkedItems={checkedItems}
                        onCheckedChange={(ev, checked) => { console.log("ProductOptionsEditor", checked); setCheckedItems(old => [...old.filter(([item, c]) => item !== checked.value), [checked.value, checked.checked]]) }}
                        className={mergeClasses(styles.section, stackStyles.tree)}
                        aria-label="Lazy Loading">

                        {/*{Array.from(flatTree.items(), (flatTreeItem) => {*/}
                        {/*    const treeItemProps = flatTreeItem.getTreeItemProps();*/}
                        {/*    const item = treeItemProps.item;*/}
                        {/*    return (*/}
                        {/*        <Subtree treeItemProps={treeItemProps as FlatTreeItemProps }*/}
                        {/*            onSelect={(optionvalue: ProductOptionValue) => setRecord({ ref: item, item: optionvalue })}*/}
                        {/*            productOptionId={item.productoptionid}*/}
                        {/*            value={item.productoption.name} />*/}
                        {/*    );*/}
                        {/*})}*/}

                        <Table arial-label="Default table" style={{ minWidth: "510px" }}>
                            <TableHeader>
                                <TableRow>

                                    <TableHeaderCell key={'label'}>
                                        Produkt
                                    </TableHeaderCell>
                                    <TableHeaderCell key={'price'}>
                                        Pris (Ekls. moms)
                                    </TableHeaderCell>
                                    <TableHeaderCell key={'tax'}>
                                        Er momspligtig
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data?.items?.map(item => <CustomerProductNode mutate={mutate} item={{ price: item.priceamount, sku: item.sku, taxable: item.taxable, unit: item.unit?.value, productpricerange: item.productpricerangecustomerproductreferences.map(x => x.productpricerange) }} size={data.items.length} level={1}
                                    onSelect={(optionvalue: ProductOptionValue) => { }}
                                    entityKey="Product Option Customer Product Reference"
                                    parentProperty="customerproductid"
                                    recordId={item.id}
                                    value={`${item["$type"]}:${item.id}`} label={item.name} />)}
                            </TableBody>

                        </Table>

                    </FlatTree>

                </div>

            </StateTabProvider>

        </div>
    )
}




export function CustomerPriceEditor() {

    const [checkedItems, setCheckedItems] = useState<Array<[TreeItemValue, TreeSelectionValue]>>([]);

    
    const [{ model }] = useEAVApp();
    //  const entity = model.getEntityFromKey("Cu");
    //  const optionValueEntity = model.getEntityFromKey("Product Option Value");

    const router = useRouter();

    //const localization = useMemo(() => ({
    //    new: capitalize(model.getLocalization("new") ?? "New"),
    //    delete: "Delete",
    //}), []);

    // const [newRecord, setNewRecord] = useState<{ ref: ProductOptionTemplateProductReference, item: Partial<ProductOption> }>();
    // const [record, setRecord] = useState<{ ref: ProductOptionTemplateProductReference, item: ProductOptionValue }>();





    const { data, isLoading, mutate } = queryEntitySWR<CustomerProduct>(model.getEntityFromKey("Customer Product"),
        new ODataBuilder<CustomerProduct>()
            .select("id", "name", "priceamount", "sku", "taxable")
            .expandCollection("productpricerangecustomerproductreferences", r => r.select("id", "productpricerange").expand("productpricerange"))
            .expand("unit", u => u)
            .build());




    console.log("ProductOptionsEditor", [checkedItems,, data]);
    const styles = useSectionStyles();
    const stackStyles = useStackStyles();
    return (
        <div className={mergeClasses(stackStyles.stack, styles.fullWidth)}>
            <StateTabProvider >

                <div className={mergeClasses(stackStyles.stack, 'horizontal', 'grow')}>

                    <FlatTree checkedItems={checkedItems}
                        onCheckedChange={(ev, checked) => { console.log("ProductOptionsEditor", checked); setCheckedItems(old => [...old.filter(([item, c]) => item !== checked.value), [checked.value, checked.checked]]) }}
                        className={mergeClasses(styles.section, stackStyles.tree)}
                        aria-label="Lazy Loading">

                        {/*{Array.from(flatTree.items(), (flatTreeItem) => {*/}
                        {/*    const treeItemProps = flatTreeItem.getTreeItemProps();*/}
                        {/*    const item = treeItemProps.item;*/}
                        {/*    return (*/}
                        {/*        <Subtree treeItemProps={treeItemProps as FlatTreeItemProps }*/}
                        {/*            onSelect={(optionvalue: ProductOptionValue) => setRecord({ ref: item, item: optionvalue })}*/}
                        {/*            productOptionId={item.productoptionid}*/}
                        {/*            value={item.productoption.name} />*/}
                        {/*    );*/}
                        {/*})}*/}

                        <Table arial-label="Default table" style={{ minWidth: "510px" }}>
                            <TableHeader>
                                <TableRow>

                                    <TableHeaderCell key={'label'}>
                                        Produkt
                                    </TableHeaderCell>
                                    <TableHeaderCell key={'price'}>
                                        Pris (Ekls. moms)
                                    </TableHeaderCell>
                                    <TableHeaderCell key={'tax'}>
                                        Er momspligtig
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data?.items?.map(item => <CustomerProductNode mutate={mutate} item={{ price: item.priceamount, sku: item.sku, taxable: item.taxable, unit: item.unit?.value, productpricerange: item.productpricerangecustomerproductreferences.map(x => x.productpricerange) }} size={data.items.length} level={1}
                                    onSelect={(optionvalue: ProductOptionValue) => { }}
                                    entityKey="Product Option Customer Product Reference"
                                    parentProperty="customerproductid"
                                    recordId={item.id}
                                    value={`${item["$type"]}:${item.id}`} label={item.name} />)}
                            </TableBody>

                        </Table>

                    </FlatTree>

                </div>

            </StateTabProvider>

        </div>
    )
}


