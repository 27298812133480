import { RegisterControl, useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { registerLineGeneratorField } from "../QuotationLineManager";
import { IRecord, queryEntitySWR } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { Combobox, ComboboxProps, useComboboxFilter, Field, tokens, makeStyles, useId, Button, Option, OptionGroup } from "@fluentui/react-components";
import { useMemo, useRef, useState } from "react";
import { Field as RJSFField, getUiOptions, getDisplayLabel, labelValue } from "@rjsf/utils";
import { registerInputControlDesignerField, useQuickFormDefinition } from "@eavfw/quickform-designer";
import { Dismiss12Regular } from "@fluentui/react-icons";
import validator from '@rjsf/validator-ajv8';
import { group } from "console";
import { QuickFormDefinition } from "@eavfw/quickform-core";

import { useEAVForm } from "@eavfw/forms";
import { SliderProperties } from "grouponline.quotationplatformhost/src/components/slider-input/SliderInput";
import { NumberProperties } from "grouponline.quotationplatformhost/src/components/number-input/NumberInput";
import { QuestionJsonModel } from "@eavfw/quickform-core";
type customerproduct = {
    name: string,
    priceamount: number,
    sku: string,
    unitid:string
} & IRecord


const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        gap: "2px",
        maxWidth: "400px",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
    },
});

type UseComboboxFilterConfig<T extends { children: React.ReactNode; value: string } | string> = {
    /** Provides a custom filter for the option. */
    filter?: (optionText: string, query: string) => boolean;

    /** Provides a custom message to display when there are no options. */
    noOptionsMessage?: React.ReactNode;

    /** Provides a way to map an option object to a React key. By default, "value" is used. */
    optionToReactKey?: (option: T) => string;

    /** Provides a way to map an option object to a text used for search. By default, "value" is used. */
    optionToText?: (option: T) => string;

    /** Provides a custom render for the option. */
    renderOption?: (option: T) => JSX.Element;
};

function defaultFilter(optionText: string, query: string) {
    if (query === '') {
        return true;
    }

    return optionText.toLowerCase().includes(query.toLowerCase());
}

function defaultToString(option: string | { value: string }) {
    return typeof option === 'string' ? option : option.value;
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

export function useComboboxGroupedFilter<T extends { children: React.ReactNode; value: string, group?:string } | string>(
    query: string,
    options: T[],
    config: UseComboboxFilterConfig<T>
) {
    const {
        filter = defaultFilter,
        noOptionsMessage = "We couldn't find any matches.",
        optionToReactKey = defaultToString,
        optionToText = defaultToString,

        renderOption = (option: T) => {
            if (typeof option === 'string') {
                return <Option key={option}>{option}</Option>;
            }

            return (
                <Option {...option} key={optionToReactKey(option)} text={optionToText(option)} value={option.value}>
                    {option.children}
                </Option>
            );
        },
    } = config;

    const filteredOptions =useMemo(() => {
        const searchValue = query.trim();

        return options.filter(option => filter(optionToText(option), searchValue));
    }, [options, optionToText, filter, query]);

    if (filteredOptions.length === 0) {
        return [
            <Option aria-disabled="true" key="no-results" text="">
                {noOptionsMessage}
            </Option>,
        ];
    }


    const groups = groupBy(filteredOptions, x => typeof x === "string" ? "default" : x.group??"default");
    if (Object.keys(groups).length > 1) {
        return Object.entries(groups).map(([group, options]) => {

            return <OptionGroup label={ group}>
                {options.map(option => renderOption(option))}
            </OptionGroup>
        });
    }
       

    return filteredOptions.map(option => renderOption(option));
}
function questionlabel(definition: QuickFormDefinition, option: string) {

    if (!definition)
        return;

    var question = Object.entries(definition.questions).find(([qkey, question]) => qkey === option);

    return question?.[1]?.schemaName ?? question?.[1]?.text ?? question?.[0];

}

function isQuestionProps<T>(question: any, inputtype: string): question is T {
    return question.inputType === inputtype;
}

function isQuestionProductReference(question: QuestionJsonModel) {

    if (question.inputType === "product-collection-input") {
        return true;
    } else if (isQuestionProps<SliderProperties>(question, "slider") && question.sliderProductId) {
        return true;
    } else if (isQuestionProps<NumberProperties>(question, "number") && question.product) {
        return true;
    }
    return false;
}
export const ProductLookupField: RJSFField = ({ onChange, formData, title, required, schema, uiSchema, registry: { globalUiOptions } }) => {
    
    const app = useModelDrivenApp();
    const { currentRecordId, currentEntityName } = useAppInfo();

    const uiOptions = getUiOptions(uiSchema, globalUiOptions);
    const { quickformpayload } = useQuickFormDefinition() ?? {};

    console.log("ProductLookupField", [title,  getDisplayLabel(validator,schema,uiSchema), schema, formData, currentRecordId, currentEntityName, uiSchema, uiOptions]);

    const isTemplate = currentEntityName === "quotationformtemplate";
    const isQuotationlineGeneratorAssosiation = currentEntityName === "quotationlinegeneratorassosiation";

    const [{ quotationformid }] = useEAVForm((state) => ({ quotationformid: isQuotationlineGeneratorAssosiation ? state.formValues.quotationformid : currentRecordId  }));

    const productQuery = new ODataBuilder<customerproduct>()
        .filter(isTemplate ?
                  `templateid eq ${currentRecordId}`
            : `customer/owner/OwnerQuotationForms/any(form: form/id eq ${quotationformid})`)
        .build();

    const { data } = queryEntitySWR<customerproduct>(
        app.getEntityFromKey(isTemplate ? "Template Product" : "Customer Product"), productQuery);

    const options = useMemo(() => {

        if (uiOptions.supportProjectReference) {

            return (data?.items.map(i => ({
                children: i.name,
                group:"Products",
                value: i.id,

            })) ?? []).concat(Object.entries(quickformpayload?.questions ?? {})
                .filter(([qkey, question]) => isQuestionProductReference(question)).map(([qkey, question]) => (
                {
                    children: question.schemaName ?? question.text ?? qkey,
                    value: qkey,
                    group:'Questions'

                }
            )));

        }

        return data?.items.map(i => ({
            children: i.name,
            value: i.id,
            
        }))??[];
    }, [data?.items, uiOptions.supportProjectReference]);


    const [query, setQuery] = useState<string>();

    const children = useComboboxGroupedFilter(query??"", options, {
        noOptionsMessage: "No products match your search.",
        optionToText: (option) => option.children
    });

    const [selectedOptions, setSelectedOptions] = useState<string[]>(typeof (formData) ==="string" ? [formData] : formData ??[]);


    const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {

        onChange(uiOptions.multiselect ? data.selectedOptions : data.optionValue);
       
        setQuery(uiOptions.multiselect ? '' : data.optionText ?? "");
       


        setSelectedOptions(data.selectedOptions);

    };
    const styles = useStyles();
    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;
    const selectedListRef = useRef<HTMLUListElement>(null);
    const comboboxInputRef = useRef<HTMLInputElement>(null);

    const onTagClick = (option: string, index: number) => {
        // remove selected option
        setSelectedOptions(selectedOptions.filter((o) => o !== option));

        // focus previous or next option, defaulting to focusing back to the combo input
        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    const labelledBy =
        selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;
   

    return (<Field  label={schema.title} required={required}>
        <div className={styles.root}>
            <Combobox autoComplete="off"
            clearable
            onOptionSelect={onOptionSelect}
            multiselect={uiOptions.multiselect as boolean}
            aria-labelledby={comboId}
            placeholder={isTemplate ? 'Select a template product' : 'Select a product'}
            defaultSelectedOptions={[formData]}
            defaultValue={options.find(x => x.value === formData)?.children}
            onChange={(ev) => setQuery(ev.target.value)}
            selectedOptions={selectedOptions}
            value={query ?? options.find(x => x.value === formData)?.children}
        >
            {children}
        </Combobox>
        {selectedOptions.length ? (
            <ul
                id={selectedListId}
                className={styles.tagsList}
                ref={selectedListRef}
            >
                {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
                <span id={`${comboId}-remove`} hidden>
                    Remove
                </span>
                {selectedOptions.map((option, i) => (
                    <li key={option}>
                        <Button
                            size="small"
                            shape="circular"
                            appearance="primary"
                            icon={<Dismiss12Regular />}
                            iconPosition="after"
                            onClick={() => onTagClick(option, i)}
                            id={`${comboId}-remove-${i}`}
                            aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                        >
                            {data?.items.find(x => x.id === option)?.sku ?? questionlabel(quickformpayload, option)}
                        </Button>
                    </li>
                ))}
            </ul>
            ) : null}
        </div>
    </Field>)
}

registerLineGeneratorField("ProductLookupField", ProductLookupField);
registerInputControlDesignerField("ProductLookupField", ProductLookupField);
RegisterControl("ProductLookupField", ProductLookupField);