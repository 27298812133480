import { InputComponentMetadata } from "@eavfw/quickform-core";
import { JSONSchema7 } from "json-schema";
import { DropdownProperties } from "./DropdownInput";
export const dropdownInputSchema: InputComponentMetadata<DropdownProperties> = {
    label: "Dropdown",
    field: {
        type: "select",
        // typeProvider: (a) => a.maxItems === 1 ? "select" : "multiselect",
        listValuesProvider: (a) => Object.entries(a.options ?? {}).map(([okey, o]) => (typeof (o) === "string" ?
            {
                label: o,
                name: okey,
                value: okey,
            } : { label: o.label, name: okey, value: okey, }))
    },
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        products: { "ui:widget": "hidden" },
        // Notice here we change the ui component from the generic quickform to our customer specific component -> ../Designer/ButtonsOptionsField
        options: {
            "ui:field": "ButtonsOptionsField",
            product: {
                "ui:field": "ProductLookupField",
                "ui:supportProjectReference": true
            }
        },
    },
    schema: {
        type: "object",

        properties: {
            text: {
                title: "Text",
                type: "string"
            },
            products: {
                type: "array",
                items: { type: "string" }
            },
            //placeholder: {
            //    title: "Placeholder",
            //    description: "Shows a helper text displayed to the end user to help guide them. E.g. 'Please select an option'",
            //    type: "string"
            //},
            //paragraph: {
            //    title: "Paragraph",
            //    type: "string"
            //},
            options: {
                type: "object",
                additionalProperties: {
                    "type": "object",
                    required: ["label"],
                    properties: {
                        //key: { "type": "string", title: "Key", description: "The key used for the option, this is also the keyboard key used to select this option" },
                        label: { "type": "string", title: "Label", description: "The label shown to the end users" },
                        product: { type: "string", title: "Product", description: "The product assosiated" },
                        // quantity: { type: "number", title: "Quantity", description: "The quantity of the product" },
                        priceMultiplier: { type: "number", title: "Price Multiplier", description: "The price multiplier of the product" }
                        //value: { "type": "number", title: "Value", description: "Used in calculations when this option is picked" },
                        //clearOthers: { type: "boolean", "title": "Clear Others", description: "When set, this options clears other options already picked" },
                        //clearOnOthers: { type: "boolean", "title": "Clear On Others", description: "When set, this option get cleared if another option is picked" }
                    }
                }
            }
        }
    } as JSONSchema7
}