
import { RegisterFeature } from "@eavfw/apps";
import "./Ribbons";
export * from "./CustomControlDemo";
export * from "./dashboard/QuotationLeadsDashboard";
export * from "./FileUploadControl";
import "./QuotationEmbedScriptComponent";
import "./powerbi";
import "./quickFormSettingsViewPreviewComponent/QuickFormSettingsViewPreviewComponent";


import "@eavfw/codeeditor";
import "@eavfw/task-management";
import "@eavfw/quickform-designer";
import "grouponline.quotationplatformhost";
import "grouponline.quotationplatformhost/src/components/input-components";
import "./QuickForm/Designer";
import "./quotations";
import "./products";
import "./customer";

RegisterFeature("WizardExpressionsProvider", (formValues: any) => {
    console.log("WizardExpressionsProvider", [formValues]);

    return {

    }
});

RegisterFeature("ExpressionsProviderAsync", (formValues: any, expression: string) => {
    console.log("ExpressionsProviderAsync", [formValues, expression]);
});