import { InputComponentType, InputProps, registerInputComponent } from "@eavfw/quickform-core";
import { ProductOptionInputSchema } from "./ProductOptionInputSchema";
import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";
import { ImageGrid } from "../product-collection-input/ProductCollectionInput";

import { DropdownInput } from "../../dropdown-input/DropdownInput";

//The options part is inserted by the backend upon save from the product id
export type ProductOptionInputProps = {
    inputType: "product-option-input";
    productQuestion: string;
    products: string[];
    useDropdown?: boolean;
    options: {
        [key: string]: {
            key: string;
            label: string;
            imageUrl?: string;
            imageHash?: string;
        }
    }
} & Omit<ButtonsProperties, "inputType">;



export const ProductOptionInput: InputComponentType<ProductOptionInputProps> = ({ inputType, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;
    const useImages = Object.values(props.options).some(x => x.imageUrl);


    console.log("ProductInput", [props, useImages]);

    if (props.useDropdown) {//@ts-ignore
        return <DropdownInput {...buttonProps} inputType="dropdown" />
    }

    if (useImages) {//@ts-ignore
        return <ImageGrid  {...buttonProps} inputType="image-grid" />
    }
    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

ProductOptionInput.inputSchema = ProductOptionInputSchema;
registerInputComponent("product-option-input", ProductOptionInput);