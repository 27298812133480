import { RegisterControl } from "@eavfw/apps";
import "./ProductOptionsEditor";

import dynamic from 'next/dynamic'

const ProductPriceEditor = dynamic(() => import('./ProductPriceEditor'), {
    loading: () => <p>Loading...</p>,
})

RegisterControl("ProductPriceEditor", ProductPriceEditor)

import { CustomerPriceDashboard } from "./CustomerPriceDashboard";

RegisterControl("CustomerPriceDashboard", CustomerPriceDashboard);
