
import { NumberProperties } from "./NumberInput";
import { QuickformState, QuestionModel, ValidationResult } from "@eavfw/quickform-core";

export const validateNumberInput = (output: any, properties: NumberProperties, model: QuestionModel, state: QuickformState): Promise<ValidationResult> => {

    console.log("validationg number", [output]);

    const value = parseFloat(output);

    return new Promise((resolve) => {

        if (typeof properties.min === "undefined" && typeof properties.max === "undefined") {
            return resolve({ isValid: true, message: '', validatedOutput: output });
        }
       
        const valid = typeof value === 'number' && value >= properties.min && value <= properties.max;
       resolve({
           isValid: valid,
           message: valid ? "" : `Værdien skal være mellem ${properties.min} og ${properties.max}.`,
           validatedOutput: value,
       });
   });
};
