"use client";
import { InputComponentType, quickformtokens, registerInputComponent, registerInputTypeValidator, useQuickForm, useFocusableQuestion } from "@eavfw/quickform-core";
import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { ChangeEvent, useState } from "react";
import { IconResolver } from "../icons/IconResolver";
import { Tooltip } from "../tooltip/Tooltip";
import { numberInputSchema } from "./NumberInputSchema";
import { validateNumberInput } from "./NumberInputValidator";

const useInputTextStyles = makeStyles({
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: 'transparent',
        color: `${quickformtokens.onSurface}`,
        width: '100%',
        ...shorthands.borderTop('none'),
        ...shorthands.borderLeft('none'),
        ...shorthands.borderRight('none'),
        ...shorthands.borderBottom("1px", "solid", quickformtokens.questionPlaceholderColor),

        ':focus': {
            ...shorthands.borderBottom("1px", "solid", `${quickformtokens.primary}`),
            paddingBottom: '8px',
        },
    },
    inputText: {
        color: `${quickformtokens.onSurface}`,
        backgroundColor: 'transparent',
        fontSize: quickformtokens.questionInputFontSize,
        marginTop: '8px',
        paddingBottom: '9px',
        width: '100%',
        ...shorthands.border('none'),

        '@media screen and (max-width: 599px)': {
            fontSize: quickformtokens.questionInputFontSize,
        },

    },

    inputIcon: {
        marginTop: '8px',
        paddingBottom: '9px',
    },
    iconLeft: {
        left: '0',
        paddingRight: '15px'
    },
    iconRight: {
        right: '0',
        paddingLeft: '15px'
    },
    unit: {
        marginTop: '8px',
        paddingBottom: '2px',
    },
    infoIcon: {
        fontSize: '8px',
    },
    infoContainer: {
        position: "absolute",
        top: "-50%",
        right: "0px",
        fontSize: "0.7em",
        '@media screen and (max-width: 599px)': {
            fontSize: "0.5em",
            left: "95%",
        },
    }

});

export type NumberProperties = {
    min: number;
    max: number;
    defaultValue?: number;
    placeholder?: string;
    unit?: string;
    helperText?: string;
    product?: string;
}


export const NumberInput: InputComponentType<NumberProperties> = ({ questionModel, unit, defaultValue, placeholder, helperText }) => {
   // const [value, setValue] = useState(defaultValue || placeholder);
    const ref = useFocusableQuestion<HTMLInputElement>(questionModel.logicalName);
    const { answerQuestion } = useQuickForm();
    const styles = useInputTextStyles();

    const resize = () => {
        const input = ref.current;
        if (!input)
            return;

        const oldvalue = input.value;

        if (!oldvalue || oldvalue === '')
            input.value = input.placeholder;

        const isOverflowed = input.scrollWidth > input.clientWidth;
        input.value = oldvalue;
        if (isOverflowed) {
            var style = window.getComputedStyle(input, null).getPropertyValue('font-size');
            input.style.fontSize = (parseFloat(style) - 1) + "px";
            resize();
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        

      //  setValue(() => event.target.value === '' ? '' : Number(event.target.value));
        answerQuestion(questionModel.logicalName, event.target.value, true);
        resize();
    }

    return (
        <div>
            <div className={styles.inputContainer}>
                <input
                    style={{ outline: 'none', }}
                    ref={ref}
                    type={"number"}
                    className={styles.inputText}
                    placeholder={questionModel.placeholder}
                    value={questionModel.output}
                    onChange={handleChange}
                />
                {unit &&
                    <div className={mergeClasses(styles.unit, styles.iconRight)}>
                        {unit}
                    </div>
                }
                {helperText &&
                    <div className={styles.infoContainer}>
                        <Tooltip
                            label={helperText}
                            direction="upperLeft"
                        >
                            <IconResolver
                                type={"Info"}
                                className={mergeClasses(styles.inputIcon, styles.iconRight, styles.infoIcon)}
                                size={18}
                                backgroundColor={"white"}
                                color={quickformtokens.primary}
                            />
                        </Tooltip>
                    </div>
                }
            </div>
        </div>
    );
}

/* This property assignment grants QuickformDesigner metadata information about which properties the inputcomponent needs */
NumberInput.inputSchema = numberInputSchema;
registerInputComponent("number", NumberInput);
registerInputTypeValidator("number", validateNumberInput);