import { RegistereControl, useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { useSectionStyles } from "@eavfw/apps/src/Styles/SectionStyles.styles";
import { queryEntitySWR } from "@eavfw/manifest";
import { Field, shorthands, tokens } from "@fluentui/react-components";
import { makeStyles, mergeClasses } from "@griffel/react";
import { useEffect, useRef } from "react";


const useQuotationEmbedScriptComponent = makeStyles({
    root: {
        width: "100%"
    },
    snippet: {
        ...shorthands.padding("0.285714rem"),
        ...shorthands.margin("0.285714rem"),
        ...shorthands.borderRadius("4px"),
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border("1px", "solid", tokens.colorTransparentStroke),
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.14) 0px 2px 4px;"

    }

});

const sanitize = (input: string) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    for (const elm of doc.querySelectorAll('*')) {
        for (const attrib of elm.attributes) {
            if (attrib.name.startsWith('on')) {
                elm.removeAttribute(attrib.name);
            }
        }
    }
    return doc.body.innerHTML;
};

function dedent(callSite: string | Function | string[], ...args: any[]) {

    function format(str: string) {

        let size = -1;

        return str.replace(/\n(\s+)/g, (m, m1) => {

            if (size < 0)
                size = m1.replace(/\t/g, "    ").length;

            return "\n" + m1.slice(Math.min(m1.length, size));
        });
    }

    if (typeof callSite === "string")
        return format(callSite);

    if (typeof callSite === "function")
        return (...args: any[]) => format(callSite(...args));

    let output = callSite
        .slice(0, args.length + 1)
        .map((text, i) => (i === 0 ? "" : args[i - 1]) + text)
        .join("");

    return format(output);
}

/**
 * QuotationEmbedScriptComponent exists twice, in host and management - 
 * 
 * this one is used inside the designer to render the preview
 * @param param0
 * @returns
 */


export const QuotationEmbedScriptComponent = (
    { showEmbed = true, showLabel = true, isSimulation = false, formid, className }:
        {
            showEmbed?: boolean, showLabel?: boolean, isSimulation?: boolean
            formid?: string
            className?: string
        }) => {

    const { currentRecordId = formid, currentEntityName="customerdashboard" } = useAppInfo();
    const sectionStyles = useSectionStyles();
    const styles = useQuotationEmbedScriptComponent();
    const app = useModelDrivenApp();
    const { data:websites } = queryEntitySWR(app.getEntityFromKey("Website Quotation Form"), `$filter=quotationformid eq ${currentRecordId}`, currentEntityName === "quotationform");

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            const script = document.createElement('script');
            script.id = "qp-loader-id";
            script.src = `${window.__env.QUOTATION_PLATFORM_BASE_URL}/scriptloader.js`;
            if (currentEntityName === "quotationform" || currentEntityName ==="customerdashboard") {
                script.setAttribute("iframesrc", `${window.__env.QUOTATION_PLATFORM_BASE_URL}/quotations/${currentRecordId}?disableCache=true&showTraceId=true${isSimulation ? '&isSimulation=true' : ''}`);
            }
            else if (currentEntityName === "quotationformtemplate") {
                script.setAttribute("iframesrc", `${window.__env.QUOTATION_PLATFORM_BASE_URL}/quotations/${currentRecordId}?isTemplate=true&disableCache=true&showTraceId=true`);
            } else {
                throw new Error("Unsupported entity: " + currentEntityName);
            }

            currentRef.appendChild(script);

            return () => {
                if (currentRef && script.parentNode)
                    currentRef.removeChild(script);
            }
        }
    }, [ref]);
    if (!showLabel) {
        return <div ref={ref} />
    }

    return <div className={mergeClasses(sectionStyles.section, styles.root, className)}>
        {showEmbed &&
            <Field label="Embed Scripts">
                <pre className={styles.snippet}>
                    <code>
                        {
                            `<script
                            id="qp-loader-id"
                            src="${window.__env.QUOTATION_PLATFORM_BASE_URL}/scriptloader.js"
                            iframesrc="${window.__env.QUOTATION_PLATFORM_BASE_URL}/quotations/${currentRecordId}" 
                            lazyload="true" loadparams="true" urlcheck="" autoscroll="true" scrollOnLoad="false" offset="0"></script>`

                        }
                    </code>
                </pre>
            </Field>
        }
        <Field label="Preview" >
            <div className={styles.snippet} ref={ref} />
        </Field>
    </div>
}

RegistereControl("QuotationEmbedScriptComponent", QuotationEmbedScriptComponent);